@import "variables.scss";

/* ---------- mixins ---------- */

@mixin transition ($time:0.3s, $easing:ease, $delay:0s) {
	-webkit-transition: all $time $easing $delay;
	-moz-transition: all $time $easing $delay;
	transition: all $time $easing $delay;
}

@mixin type-inter ($fz:1.6rem, $fw:400, $lh:1.75, $ls:0) {
	font-family: $inter-font;
	font-size: $fz;
	font-weight: $fw;
	letter-spacing: $ls;
	line-height: $lh;
}
@mixin type-rubik ($fz:1.6rem, $fw:400, $lh:1.75, $ls:0) {
	font-family: $rubik-font;
	font-size: $fz;
	font-weight: $fw;
	letter-spacing: $ls;
	line-height: $lh;
}
:root {
	--black: #000;
	--white: #FFF;
	--blue: #2D72CE;
	--blue-btn: #3A91F0;
	--blue-bg: #3074D0;
	--line: #F1F2F3;
	--grey: #0A1622;
	/* --grey-title: #333333; */
	--grey-800: #4A5463;
	--grey-700: #5C6776;
	--grey-600: #5C6776;
	--grey-500: #848F9E;
	--grey-400: #A4AFBF;
	--grey-300: #C9D4E5;
	--grey-200: #DAE5F7;
	--grey-100: #E5F1FE;
	--grey-50: #EEF9FE;
	--grey-6: #F2F2F2;
	--grey-4: #BDBDBD;

	
	--grey-bg-500: #E6E7E8;
	--grey-bg-200: #FAFBFC;
	--grey-bg-50: #F2F6FD;

	--grey-bg-th: #F5F5F5;
	--grey-bg-300: #F5F6F7;

	--blue-100: #BFDCF9;
	--blue-200: #98C6F6;
	--blue-300: #70B0F3;
	--toggle-ease: cubic-bezier(.77,0,.175,1);
}

/* ---------- typography ---------- */

* {
	&::-moz-selection {
		color: var(--white);
		background: var(--blue);
	}
}

* {
	&::-webkit-selection,
	&::selection {
		color: var(--white);
		background: var(--blue);
	}
}


* {
	// firefox
	scrollbar-color: var(--blue) #fff;
  	scrollbar-width: thin; 


  // chrome, safari
  &::-webkit-scrollbar {
  	width: 0.6rem;
  }

  &::-webkit-scrollbar-track {
  	background: #fff; 
  }

  ::-webkit-scrollbar-thumb {
  	background-color: var(--blue);
  	outline: 0px solid #fff;
  	-webkit-border-radius: 0;
  	border-radius: 0; 
  }
}



html {
	font-size: 62.5%;
}

body {
	//@include type();
	color: var(--grey-600);
	background: var(--white);
	@include type-inter();
	

}

.base-bottom-margin {
	margin-bottom: 1.6rem;
}
.title-bottom-margin {
	margin-bottom: 2.4rem;
}

h1 {
	@extend .title-bottom-margin;
	@include type-rubik(8rem, 400, 1.2, 0.05rem);
	color: var(--grey);
}

h2 {
	@extend .title-bottom-margin;
	@include type-rubik(5.6rem, 600, 1.14, 0.02rem);
	color: var(--grey);
}

h3 {
	@extend .title-bottom-margin;
	@include type-rubik(4rem, 600, 1.4, 0.02rem);
	color: var(--grey);
}

h4 {
	@extend .title-bottom-margin;
	@include type-rubik(3.6rem, 400, 1.44, 0.02rem);
	color: var(--grey);
}

h5 {
	@extend .title-bottom-margin;
	@include type-rubik(3.2rem, 400, 1.5, 0.02rem);
	color: var(--grey);
}

h6 {
	@extend .title-bottom-margin;
	@include type-rubik(2.4rem, 600, 1.5, 0.02rem);
	color: var(--grey);
}
.title{
	@include type-rubik(1.8rem, 700, 1.33, 0.02rem);
}

.subtitle{
	@include type-rubik(1.6rem, 600, 1.375, 0.02rem);
	margin-bottom: 1.2rem;
	color: var(--blue);
}
.link{
	@include type-rubik(1.8rem, 700, 1.33, 0.02rem);
	color: var(--grey-800);

}

.overlay{
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;

	&.desc{
		
	}

	&.mob{
		display: none
	}
}
p {
	@extend .base-bottom-margin;
}

ul {
	list-style-type: disc;
	margin-left: 17px;
	@extend .base-bottom-margin;
}

ol {
	@extend .base-bottom-margin;
}

a {
	text-decoration: none;
	color: var(--blue-btn);
	&:hover{
		color: var(--blue-300);
	}
	&:active{
		color: var(--blue);
	}
}

a,
button {
	@include transition();
}


form {
	width: 100%;
	position: relative;

	input,
	select,
	textarea {
		//@include type(ff, fz, fw, tt, lh);
		height: i;
		line-height: 1;
		color: i;
		background: i;
		border: 0 none;
		border-radius: i;
		padding: i;
		margin: i;

		&::-moz-placeholder {
			color: i;
			opacity: 1;
		}
		&::-ms-input-placeholder {
			color: i;
			opacity: 1;
		}
		&::-webkit-input-placeholder {
			color: i;
			opacity: 1;
		}

		&:focus {
			border-color: i;
			&::-moz-placeholder {
				
			}
			&::-ms-input-placeholder {
				
			}
			&::-webkit-input-placeholder {
				
			}
		}
	}

	select {
		background-image: url('img/');
	}

	textarea {
		height: i;
		padding: i;
	}

	input[type="submit"] {
		@include transition();
	}
}


.container-bigger {
	width: 100%;
	max-width: i;
	padding-left: 15px;
	padding-right: 15px;

	&::after {
		content: '';
		display: table;
		clear: both;
	}
}


.container-bigest {
	width: 100%;
	max-width: i;
	padding-left: 15px;
	padding-right: 15px;

	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

.row{
	display: flex;

	.left{
		width: 50%
	}
	.right{
		width: 50%
	}
}
.btn {
	@include type-rubik(1.6rem, 600, 5.6rem, 0);
	display: inline-block;
	height: 5.6rem;
	color: var(--white);
	text-align: center;
	min-width: 18.7rem;
	background: var(--blue-btn);
	border: none;
	border-radius: 0.8rem;
	padding: 0 2.4rem;
	text-decoration: none;
	box-shadow: none;
	text-transform: uppercase;
	&::before,
	&::after {
		content: '';
	}

	&:hover, &:focus, &:active, &:visited {
		text-decoration: none;
	}
	&:hover{
		background: var(--blue-300);
		color: var(--white);
	}
	&:active{
		background: var(--blue);
		color: var(--white);
	}
}
.btn-white {
	@include type-rubik(1.6rem, 600, 5.6rem, 0);
	display: inline-block;
	height: 5.6rem;
	color: var(--blue);
	text-align: center;
	min-width: 18.7rem;
	background: var(--white);
	border: none;
	border-radius: 0.8rem;
	padding: 0 2.4rem;
	text-decoration: none;
	box-shadow: none;
	text-transform: uppercase;
	&::before,
	&::after {
		content: '';
	}

	&:hover, &:focus, &:active, &:visited {
		text-decoration: none;
	}
	&:hover{
		color: var(--blue-300);
	}
	&:active{
		color: var(--blue);
	}
}
.btn-user{
	@include type-rubik(1.6rem, 600, 5.6rem, 0);
	height: 5.6rem;
	color: var(--blue-btn);
	text-align: center;
	min-width: 18.7rem;
	background: none;
	border: none;
	padding: 0 3.2rem;
	border-radius: 0.8rem;
	text-decoration: none;
	box-shadow: none;
	border: 0.2rem solid var(--blue-btn);
	text-transform: uppercase;
	align-items: center;
		display: inline-flex;
		justify-content: center;
	&::before {
		content: '';
		background-image: url("img/li_users.png");
		width: 2.4rem;
		height: 2.4rem;
		align-items: center;
		display: inline-flex;
		margin-right: 1.2rem;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
		@include transition(0.1s ease 0s);
	}

	&:hover, &:focus, &:active, &:visited {
		text-decoration: none;
	}
	&:hover{
		border-color: var(--blue-300);
		color: var(--blue-300);
		&::before {
			filter: brightness(0) saturate(100%) invert(66%) sepia(21%) saturate(1028%) hue-rotate(173deg) brightness(96%) contrast(98%);
		}
	}
	&:active{
		border-color: var(--blue);
		color: var(--blue);
		&::before {
			filter: brightness(0) saturate(100%) invert(35%) sepia(74%) saturate(1071%) hue-rotate(187deg) brightness(94%) contrast(86%);
		}
	}
}
.btn-show{
	@include type-rubik(1.6rem, 600, 5.6rem, 0.05rem);
	height: 5.6rem;
	color: var(--blue-btn);
	text-align: center;
	min-width: 18.7rem;
	background: none;
	border: none;
	padding: 0 3.2rem;
	border-radius: 0.8rem;
	text-decoration: none;
	box-shadow: none;
	border: 0.2rem solid var(--blue-btn);
	text-transform: uppercase;
	align-items: center;
		display: inline-flex;
		justify-content: center;
	&::before {
		content: '';
		background-image: url("img/li_clipboard-list.png");
		width: 2.4rem;
		height: 2.4rem;
		align-items: center;
		display: inline-flex;
		margin-right: 1.2rem;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
		@include transition(0.1s ease 0s);
	}

	&:hover, &:focus, &:active, &:visited {
		text-decoration: none;
	}
	&:hover{
		border-color: var(--blue-300);
		color: var(--blue-300);
		&::before {
			filter: brightness(0) saturate(100%) invert(66%) sepia(21%) saturate(1028%) hue-rotate(173deg) brightness(96%) contrast(98%);
		}
	}
	&:active{
		border-color: var(--blue);
		color: var(--blue);
		&::before {
			filter: brightness(0) saturate(100%) invert(35%) sepia(74%) saturate(1071%) hue-rotate(187deg) brightness(94%) contrast(86%);
		}
	}
	
}
.btn-arrow {
	@include type-rubik(1.6rem, 600, 5.6rem, 0);
	height: 5.6rem;
	color: var(--white);
	text-align: center;
	min-width: 18.7rem;
	background: var(--blue-btn);
	border: none;
	padding: 0 2.4rem;
	border-radius: 0.8rem;
	text-decoration: none;
	box-shadow: none;
	text-transform: uppercase;
	align-items: center;
		display: inline-flex;
		justify-content: center;
	&::after {
		content: '';
		background-image: url("img/arrow-right.png");
		width: 2.4rem;
		height: 2.4rem;
		align-items: center;
		display: inline-flex;
		margin-left: 1.2rem;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
		@include transition(0.15s ease 0s);
	}

	&:hover, &:focus, &:active, &:visited {
		text-decoration: none;
	}
	&:hover{
		background: var(--blue-300);
		color: var(--white);
	}
	&:active{
		background: var(--blue);
		color: var(--white);
	}
}
.btn-tr-arrow {
	@include type-rubik(1.6rem, 600, 5.6rem, 0);
	height: 5.6rem;
	color: var(--white);
	text-align: center;
	min-width: 18.7rem;
	background: none;
	border: none;
	padding: 0 2.2rem;
	border-radius: 0.8rem;
	border: 0.2rem solid var(--white);
	text-decoration: none;
	box-shadow: none;
	text-transform: uppercase;
	align-items: center;
		display: inline-flex;
		justify-content: center;
	&::after {
		content: '';
		background-image: url("img/arrow-right.png");
		width: 2.4rem;
		height: 2.4rem;
		align-items: center;
		display: inline-flex;
		margin-left: 1.2rem;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
		@include transition(0.15s ease 0s);

	}

	&:hover, &:focus, &:active, &:visited {
		text-decoration: none;
	}
	&:hover{
		color: var(--blue-100);
		border: 0.2rem solid var(--blue-100);
		&::after{
			filter:  brightness(0) saturate(100%) invert(100%) sepia(94%) saturate(5298%) hue-rotate(174deg) brightness(99%) contrast(97%);
		}
	}
	&:active{
		color: var(	--blue-200);
		border: 0.2rem solid var(--blue-200);

		&::after{
			filter: brightness(0) saturate(100%) invert(77%) sepia(5%) saturate(3651%) hue-rotate(182deg) brightness(101%) contrast(93%);
		}
	}
}
.btn-tr {
	@include type-rubik(1.6rem, 600, 5.2rem, 0);
	display: inline-block;
	height: 5.6rem;
	color: var(--blue-btn);
	text-align: center;
	min-width: 18.7rem;
	background: transparent;
	border: 0.2rem solid var(--blue-btn);
	border-radius: 0.8rem;
	padding: 0 2.4rem;
	text-decoration: none;
	box-shadow: none;
	text-transform: uppercase;
	&::before,
	&::after {
		content: '';
	}

	&:hover, &:focus, &:active, &:visited {
		text-decoration: none;
	}
	&:hover{
		border-color: var(--blue-300);
		color: var(--blue-300);
	}
	&:active{
		border-color: var(--blue);
		color: var(--blue);
	}
}

.slider__nav {
	display: flex;
	.nav__prev{
		margin-right: 1.6rem;
	}
}
.nav__prev {
	width: 4.8rem;
	height: 4.8rem;
	border-radius: 50%;
	position: relative;
	background: var(--blue-btn);
	cursor: pointer;
	@include transition();
	&::after{
		content: '';
		background-image: url('img/arrow-slider.png');
		background-size: contain;
		background-repeat: no-repeat;
		position: absolute;
		top: 52%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(180deg);
		width: 2.2rem;
		height: 2.2rem;
		@include transition();

	}
	&:hover{
		background: var(--blue-300);
	}
	&:active{
		background: var(--blue);
	}
	&:disabled{
		background: var(--grey-300);

	}
}
.nav__next {
	width: 4.8rem;
	height: 4.8rem;
	border-radius: 50%;
	position: relative;
	background: var(--blue-btn);
	cursor: pointer;
	@include transition();
	&::after{
		content: '';
		background-image: url('img/arrow-slider.png');
		background-size: contain;
		background-repeat: no-repeat;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 2.2rem;
		height: 2.2rem;
		@include transition();

	}
	&:hover{
		background: var(--blue-300);
	}
	&:active{
		background: var(--blue);
	}
	&:disabled{
		background: var(--grey-300);

	}
}

.slider-progress{
	position: relative;
	left: 0;
	bottom: 0;
	width: 100%;
	max-width: 25.7rem;
	height: 0.5rem;
	background: var(--grey-bg-50);
	border-radius: 0.3rem;
}
.slider-progress span{
	position: absolute;
	left: 0;
	bottom: 0;
	display: block;
	border-radius: 0.3rem;
	width: 0px;
	height: 100%;
	background: var(--blue-btn);
	transition: all 0s linear;
}
.slider-progress span.active{
	width: 100%;
}
.slider__progress__wrapper {
	display: flex;
	align-items: center;
	margin-bottom: 2rem;
	.slider-progress {
		margin-right: 2rem;
	}
	.slides__number__wrapper {
		display: flex;
		align-items: center;
	}
	.number__slide {
		@include type-rubik(2.8rem, 400, 1.3, 0.02rem);
		color: var(--grey-800);
		margin-right: 0.6rem
	}
	.number__slides {
		@include type-inter(1.4rem, 400, 1.71, 0);
		color: var(--grey-500);
	}
}
/* .slick-list,
.slick-track {
		height: 100%; } */
.slick-dots{
	display: flex;
	height: 3rem;
	align-items: center;
	justify-content: center;
	list-style: none;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0;
	li{
		line-height: 0;
		font-size: 0;
		&.slick-active{

			button{
				background-color: var(--blue-btn);
				width: 1rem;
				height: 1rem;
			}
		}
	}
	button{
		font-size: 0;
		line-height: 0;
		width: 0.6rem;
		height: 0.6rem;
		border-radius: 50%;
		background-color: var(--grey-200);
		margin: 0 0.6rem;
		position: relative;
		&::after{
			content: '';
			width: 2rem;
			height: 2rem !important;
			position: absolute;
			left: 50%;
			height: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.container{
	max-width: 172rem;
	width: 100%;
	padding-left: 3rem;
	padding-right: 3rem;
	margin: 0 auto;
}



.main{
	padding-top: 11rem;
}
/* ---------- header start------------ */

.main__header {
	height: 11rem;
	position: fixed;
	width: 100%;
	display: flex;
	align-items: center;
	background: var(--white);
	z-index: 100;
	ul{
		list-style-type: none;
		margin-left: 0;
		margin-bottom: 0;
	}
	a{
		white-space: nowrap;
	}
	.header__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.logo {
		max-width: calc(15rem + 3rem);
		a{
			padding: 1.8rem 1.5rem;
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: center;
		}
		img{
			max-width: 100%;
			width: 100%;
		}
	}
	.header__menu {
		display: flex;
		align-items: center;
	}
	.header__menu__inner {
		display: flex;
		align-items: center;
	}
	.header__menu__box {
		display: flex;
		align-items: center;
	}
	.header__menu__wrapper{
		display: flex;
		align-items: center;
	}
	.main__nav {

		.menu {
			display: flex;
		}
		.menu__item {

			a{
				padding: 2rem;
				display: inline-block;
				color: var(--grey-400);
				@include type-rubik(1.6rem, 600, 1.375, 0);
				text-transform: uppercase;
				position: relative;
				&::after{
					content: '';
					width: 0.4rem;
					height: 0.4rem;
					position: absolute;
					left: 0.5rem;
					top: 50%;
					transform: translateY(-50%);
					background: var(--blue-btn);
					@include transition();
					opacity: 0;
					visibility: hidden;
					border-radius: 50%;
				}
					
				&:hover{
					color: var(--blue-btn);

					&::after{
						opacity: 1;
						visibility: visible;
					}
				}
				/* &.active-link{
					color: var(--blue-btn);

					&::after{
						opacity: 1;
						visibility: visible;
					}
				} */
			}
		}
	}
	
	.menu__item {
	}
	.header__menu__right {
		display: flex;
		align-items: center;
		margin-left: 1.8rem;
	}
	.menu__right {
		display: flex;
		
		.menu__item {
			display: inline-block;
			a{
				display: flex;
				align-items: center;
				padding: 1rem;
				@include type-inter(1.6rem, 600, 1.25, 0.02rem);
				color: var(--grey-700);


				&:hover{
					color: var(--blue-btn);


					img{
						filter: brightness(0) saturate(100%) invert(51%) sepia(72%) saturate(2566%) hue-rotate(191deg) brightness(97%) contrast(94%);
					}
				}
			}
			img{
				display: inline-block;
				margin-right: 1.2rem;
				width: 2.4rem;
				height: 2.4rem;
				@include transition(0.15s ease 0s);

			}
			&.sub.show > a{
				
					color: var(--blue-btn);


					img{
						filter: brightness(0) saturate(100%) invert(51%) sepia(72%) saturate(2566%) hue-rotate(191deg) brightness(97%) contrast(94%);
					}
			}
		}
	}
	.btn {
		padding: 0 3.2rem;
		height: 4rem;
		line-height: 4rem;
		margin-left: 1rem;
	}
}
.sub.menu__item a{
	position: relative;
	
	&::after{
		content: '';
		background-image: url('img/li_chevron-left.png');
		background-size: contain;
		background-repeat: no-repeat;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translatey(-50%) rotate(-90deg);
		width: 2.4rem;
		height: 2.4rem;
		@include transition();

	}
}
.submenu__wrapper {
	.sub-menu {
		top: 11rem;
		position: fixed;
		bottom: 0;
		right: 0;
		z-index: 100;
		background: var(--white);
		box-shadow: 0 0.5rem 1rem rgba(196, 196, 196, 0.5);
		display: flex;
		flex-direction: column;
		max-width: 59rem;
		width: 100%;
		padding: 4.5rem 3.8rem;
		transition: all 0.8s var(--toggle-ease);
		transform: translateX(100%);

		&.show{
			transform: translateX(0);
		}
	}
	.sub__menu__list{
		display: flex;
		flex-direction: column;
		.menu__item{
			a{

				&:hover{
					color: var(--blue-btn);
				}
			}
		}
		
	}
	.submenu__top{
		margin: 0 2.2rem 4rem;
	}
	.menu__item {
		display: inline-block;
		margin-bottom: 1.6rem;
		a{
			display: flex;
			align-items: center;
			padding: 1rem;
			@include type-rubik(1.6rem, 600, 1.375, 0);
			color: var(--grey);
			text-transform: uppercase;
			padding: 1.6rem 3.2rem;

		}
		img{
			display: inline-block;
			margin-right: 1.2rem;
			width: 2.4rem;
			height: 2.4rem;
		}
	}
	.close {
		display: flex !important; 
		justify-content: space-between;
		margin-bottom: 0rem;
		align-items: center;
		@include type-inter(2.4rem, 400, 1.5, 0);
		color: var(--grey);

		img{
			margin-right: 0;
			position: relative;
			
		}
	}
	.back {
		display: none !important;
		a{
			@include type-inter(2rem, 400, 1.6, 0);
			color: var(--grey);		
			padding: 0;
			text-transform: none;
		}
	}
	.menu__title {
		display: none !important;
		@include type-rubik(1.6rem, 600, 1.375, 0.02rem);
		color: var(--grey-600);
		

	}
	.btn__menu  a{
		padding: 1.2rem 3.1rem;
		color: var(--blue-btn);
		img{
			order: 2;
			margin-right: 0;
			margin-left: 1.2rem;
		}
	}
}
.main__header .main__nav_overlay {
	opacity: 0;
	visibility: hidden;
	background: rgba(0, 0, 0, 0.3);
	position: fixed;
	transition: all 0.8s var(--toggle-ease);
	top: 11rem;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100vw;
	height: calc(100vh - 11rem);
	z-index: -99;

	&.show{
		opacity: 1;
		visibility: visible;
		z-index: 99;
	}
}

/* ---------- hamburger -------- */
.hamburger {
	position: relative;
	display: block;
	width: 2.1rem;
	height: 1.6rem; 
	margin-right: 0.3rem;

	&::after{
		content: '';
		width: 4rem;
		height: 4rem;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}
  
  .hamburger span {
	position: absolute;
	display: block;
	height: 0.2rem;
	left: 0;
	right: 0;
	background: var(--blue-btn);
	border-radius: 0.1rem; }
  
  .hamburger span:first-child {
	top: 0;
	-webkit-transition: top 0.3s ease 0.3s, -webkit-transform 0.3s ease 0s;
	transition: top 0.3s ease 0.3s, -webkit-transform 0.3s ease 0s;
	-o-transition: top 0.3s ease 0.3s, transform 0.3s ease 0s;
	transition: top 0.3s ease 0.3s, transform 0.3s ease 0s;
	transition: top 0.3s ease 0.3s, transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
  }  
  .hamburger span:nth-child(2) {
	top: 55%;
	margin-top: -0.2rem;
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s; }
  
  .hamburger span:last-child {
	top: auto;
	bottom: 0;
	-webkit-transition: bottom 0.3s ease 0.3s, -webkit-transform 0.3s ease 0s;
	transition: bottom 0.3s ease 0.3s, -webkit-transform 0.3s ease 0s;
	-o-transition: bottom 0.3s ease 0.3s, transform 0.3s ease 0s;
	transition: bottom 0.3s ease 0.3s, transform 0.3s ease 0s;
	transition: bottom 0.3s ease 0.3s, transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s; }
  
  .hamburger.show span:first-child {
	top: 40%;
	-webkit-transition: top 0.3s ease 0s, -webkit-transform 0.3s ease 0.3s;
	transition: top 0.3s ease 0s, -webkit-transform 0.3s ease 0.3s;
	-o-transition: top 0.3s ease 0s, transform 0.3s ease 0.3s;
	transition: top 0.3s ease 0s, transform 0.3s ease 0.3s;
	transition: top 0.3s ease 0s, transform 0.3s ease 0.3s, -webkit-transform 0.3s ease 0.3s;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg); }
  
  .hamburger.show span:nth-child(2) {
	opacity: 0; }
  
  .hamburger.show span:last-child {
	bottom: 48%;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-webkit-transition: bottom 0.3s ease 0s, -webkit-transform 0.3s ease 0.3s;
	transition: bottom 0.3s ease 0s, -webkit-transform 0.3s ease 0.3s;
	-o-transition: bottom 0.3s ease 0s, transform 0.3s ease 0.3s;
	transition: bottom 0.3s ease 0s, transform 0.3s ease 0.3s;
	transition: bottom 0.3s ease 0s, transform 0.3s ease 0.3s, -webkit-transform 0.3s ease 0.3s; }
  
	
@media (min-width: 1199px) {
	
	.hamburger {
		display: none !important;
	
	}
	.sub.menu__item a::after{
		display: none;
	  }
}


/* ---------- hamburger - end -------- */
/* ---------- header end ------------ */

/* ------------------------------------------------------------ Home page -------------------------------------------------------- */
/* ---------- Home Intro ------------ */

.intro {
	position: relative;
	.intro__inner {
		display: flex;
	
	}
	p{
		@include type-inter(2.4rem, 400, 1.5, 0);
		color: var(--grey-600);
		margin-bottom: 3.2rem;
	}
	.content__side {
		width: 50%;
		
	}
	.left {
		width: 100%;
		max-width: 86rem;
		padding-right: 4.3rem;
		padding-left: 3rem;
		margin-right: 0;
		margin-left: auto;
		display: flex;
		align-items: center;
	}
	
	.content__inner {
	}
	.btn-arrow {
		margin-right: 2rem;
	}
	.btn-tr {
	}
	.right {
	}
	.intro__img__wrapper{
		position: relative;
		/* padding-top: 88.2%; */
		min-height: 82rem;

	}
	.intro__img {
		position: absolute;
		left: 0;
		top: 0;
		background-repeat: no-repeat;
		background-position: center left;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

	.bg-wrapper{
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		
		.overlay{
			width: auto;
			height: auto;
			position: absolute;
			left: -35rem;
			top: -67%;
		}
	}
	.shadow-block{
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		opacity: 0;
		.overlay{
			width: auto;
			height: auto;
			position: absolute;
			left: -35rem;
			top: -15%;
		}

	}

	.elt__intro_overlay {
	  position: absolute;
	  left: 0;
	  top: 0;
	  width: 100%;
	  height: 100%;
	  z-index: 0; }
	  .elt__intro_overlay_inner {
		height: 100%;
		width: auto;
		position: relative;
		background: var(--blue-bg);
		left: 0%;
		-webkit-clip-path: polygon(0 0, 100000% 0, 100000% 100%, 0 100%);
				clip-path: polygon(0 0, 100000% 0, 100000% 100%, 0 100%); }
	.elt__intro_content {
	  position: relative;
	  width: 100%;
	  max-width: 170rem;
	  margin: 0 auto;
	  padding: 0 1.5rem;
	  display: -webkit-box;
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display: flex;
	  font-size: 2rem;
	  line-height: 1.75;
	  padding: 7rem 0;
	  height: 42.7vw;
	  max-height: 82rem;
	  min-height: 82rem; }
	  .elt__intro_content_side {
		width: 50%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
			-ms-flex-direction: column;
				flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
			-ms-flex-pack: center;
				justify-content: center; }
	.intro .cards__wrapper {
	  position: relative;
	  height: 100%;
	  overflow: hidden; 
	
		.shadow-block__overlay{
			width: 3958px;
			height: 1107px;

		}
		.bg-wrapper__overlay{
			width: 3958px;
			height: 1396px;

		}
	}
	  .intro .cards__wrapper .stripe-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 2; }


	.intro .stripe-wrapper__inner{
	  width: auto;
	  height: auto;
	  position: absolute;
	  left: -35rem;
	  top: -53%;
	  z-index: 2;
		
	}
	.intro .cards.scanner {
	   width: auto;
	  height: auto;
	  position: absolute;
	  left: -35rem;
	  top: -53%;
	}
	.intro .cards {
	   width: auto;
	  height: auto;
	  position: absolute;
	  
	  z-index: 2;
	 opacity: 0;
	  visibility: hidden; 
	
		.inner{
			position: absolute;
			top: -53%;
		}
	}
	  .intro .cards .front {
		opacity: 0; }
	  .intro .cards .dot .blue {
		-webkit-animation: pulse 2s ease-in-out infinite;
				animation: pulse 2s ease-in-out infinite; }
  
  @-webkit-keyframes pulse {
	0% {
	  opacity: 0; }
	50% {
	  opacity: 1; }
	100% {
	  opacity: 0; } }
  
  @keyframes pulse {
	0% {
	  opacity: 0; }
	50% {
	  opacity: 1; }
	100% {
	  opacity: 0; } }
	  .elt__intro .cards .dot.dot-1 .blue {
		-webkit-animation-delay: 0s;
				animation-delay: 0s; }
	  .elt__intro .cards .dot.dot-2 .blue {
		-webkit-animation-delay: 0.2s;
				animation-delay: 0.2s; }
	  .elt__intro .cards .dot.dot-3 .blue {
		-webkit-animation-delay: 0.4s;
				animation-delay: 0.4s; }
	  .elt__intro .cards .cloud-inner.animate {
		-webkit-animation: cloud 3s ease-in-out infinite;
				animation: cloud 3s ease-in-out infinite; }
  
  @-webkit-keyframes cloud {
	0% {
	  -webkit-transform: translateY(0px);
			  transform: translateY(0px); }
	20% {
	  -webkit-transform: translateY(-20px);
			  transform: translateY(-20px); }
	70% {
	  -webkit-transform: translateY(-20px);
			  transform: translateY(-20px); }
	90% {
	  -webkit-transform: translateY(0px);
			  transform: translateY(0px); }
	100% {
	  -webkit-transform: translateY(0px);
			  transform: translateY(0px); } }
  
  @keyframes cloud {
	0% {
	  -webkit-transform: translateY(0px);
			  transform: translateY(0px); }
	20% {
	  -webkit-transform: translateY(-20px);
			  transform: translateY(-20px); }
	70% {
	  -webkit-transform: translateY(-20px);
			  transform: translateY(-20px); }
	90% {
	  -webkit-transform: translateY(0px);
			  transform: translateY(0px); }
	100% {
	  -webkit-transform: translateY(0px);
			  transform: translateY(0px); } }
	  .elt__intro .cards .aura-inner.animate {
		-webkit-animation: aura 3s ease-in-out infinite;
				animation: aura 3s ease-in-out infinite; }
  
  @-webkit-keyframes aura {
	0% {
	  opacity: 0; }
	20% {
	  opacity: 1; }
	50% {
	  opacity: 1; }
	80% {
	  opacity: 0; }
	100% {
	  opacity: 0; } }
  
  @keyframes aura {
	0% {
	  opacity: 0; }
	20% {
	  opacity: 1; }
	50% {
	  opacity: 1; }
	80% {
	  opacity: 0; }
	100% {
	  opacity: 0; } }
/* ---------- Home Intro end ------------ */
/* ---------- Trusted ------------ */

.trusted {
	padding: 12.2rem 0 0;
	
	h6{
		@include type-rubik(2rem, 600, 1.2, 0);
		color: var(--black);
		margin-bottom: 4rem;
		text-align: center;
	}
	p{
		margin-bottom: 0;
		margin-top: 4.8rem;
		text-align: center;
	}
	.trusted__inner {
	}
	.container {
		max-width: 156rem;
	}
	.trusted__items {
		display: flex;
		flex-wrap: wrap;
		width: calc(100% + 12rem);
		margin: -2.5rem -6rem;
	}
	.trusted__item {
		margin: 2.5rem 6rem;
		/* max-width: 15rem; */
		width: calc(16.66% - 12rem);
	}
}


/* ---------- Trusted end ------------ */

/* ---------- Product ---------------- */
.product {
	padding: 18rem 0;
	h6{
		margin-bottom: 1.6rem;
	}
	p{
		@include type-inter(1.8rem, 400, 1.78, 0);
		margin-bottom: 0;
	}
	.container {
	}
	.product__inner {
		display: flex;
		justify-content: space-between;
	}

	.content__side {

		&.left{
			width: 27.77%;
			color: var(--white);
			position: relative;
			padding: 9.3rem 3rem 9.3rem 5rem;
			min-height: 78.2rem;
			h2{
				color: var(--white);
				margin-bottom: 2.4rem;
			}
			p{
				margin-bottom: 4.3rem;
			}
		}
		&.right {
			width: calc(72.23% - 4rem);
			padding: 5rem 0 2.8rem 2rem;
			p{

			}
		}
	}
	.left {
	}
	.product__overlay {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		border-radius: 1.6rem;
	}
	.subtitle {
		color: var(--grey-100);
	}
	.btn-white {
		min-width: 25rem;
	}
	.right {
	}
	
	.product__items {
		display: flex;
		width: calc(100% + 4rem);
		margin: -1.6rem -2rem;
		flex-wrap: wrap;
	}
	.product__item {
		width: calc(33.33% - 4rem);
		margin: 1.6rem 2rem;

	}
	.product__icon {
		max-width: 4.2rem;
		width: 100%;
		margin-bottom: 1.6rem;
	}
}

/* ---------- Product end------------- */

/* ---------- explorer  ------------------ */

.explorer {
	min-height: 100vh;
	.content__side__top{
		h2{
			margin-bottom: 2.4rem
		}
		p{
			@include type-inter(1.8rem, 500, 1.78, 0);
			color: var(--grey-800);
			margin-bottom: 0rem;
			max-width: 47rem;
		}
		margin-bottom: 3.2rem;
	}
	
	.explorer__inner {
		display: flex;
		align-items: stretch;
	}
	
	.content__side {
		width: 50%;
	}
	.left {
	
		.content__side__inner {
			max-width: 74.1rem;
			width: 100%;
			padding-left: 3rem;
			padding-right: 13rem;
			margin-left: auto;
			margin-top: 50vh;
			position: relative;
	
		}
	}
	.right {
		position: relative;
		
		.content__side__inner {
			position: relative;
			min-height: 100vh;
			/* min-height: 105rem; */
			/* top: 0; */
			bottom: 0;
			width: 100%;
			display: flex;
			flex-direction: column;
		}
	}
	&.sticky{
		.right {
			
			.content__side__inner {
				position: fixed;
				width: 100%;
			}
		}
	}
	&.fixed{
		.right {
			
			.content__side__inner {
				position: absolute;
				width: 100%;
				bottom: 0;
				top: auto;
			}
		}
	}
	
	
	.subtitle {
	}
	.line{
		max-width: 55rem;
		width: 100%;
		background: var(--grey-bg-500);
		height: 0.1rem;
		margin-bottom: 1.5rem;
		display: block;
	}
	.explorer__items {
		max-width: 50rem;
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-bottom: 4.4rem;
	}
	.explorer__item {
		display: flex;
		margin-bottom: 4.8rem;

		&:last-child{
			margin-bottom: 0;
		}
	}
	.explorer__item__img {
		width: 4.2rem;
		min-width: 4.2rem;
		margin-right: 2rem;
		@include transition();

	}
	.explorer__item__content {
		padding-top: 1.2rem;
	
		h6{
			@include type-inter(1.8rem, 500, 1.56, 0);
			color: var(--grey-800);
			margin-bottom: 1.6rem;
			@include transition();
		}
		p{
			@include type-inter(1.6rem, 400, 1.75, 0);
			color: var(--grey-600);
			margin-bottom: 0;
		}
	}
	.explorer__steps {
		position: relative;
		display: flex;
		flex-direction: column;
		max-width: 43.8rem;
		width: 100%;
		margin-left: 0.6rem;
	}
	.explorer__step {
		display: flex;
		min-height: 11.2rem;
		margin-bottom: 1.6rem;
		z-index: 1;


		&.visible{

			.explorer__step__number {
				
				h6{
				
					color: var(--blue);
				}
				
			}
		}
	}
	.explorer__step__number {
		display: flex;
		flex-direction: column;
		text-align: center;
		margin-right: 2.7rem;
		position: relative;
		h6{
			margin-bottom: 0;
			color: var(--grey-400);
		}
		span{
			margin-top: -0.8rem;
			@include type-rubik(1rem, 700, 2, 0.05rem);
			color: var(--grey-600);
			text-transform: uppercase;
			


		}
	}
	.explorer__step__text {
		padding-top: 0.8rem;
		@include type-inter(1.6rem, 400, 1.75, 0);
		color: var(--grey-600);

		p{
			margin-bottom: 0;
		}
	}
	.right {
	}
	.overlay {
		background-position: left center;
	}
	.explorer__img {
		margin-left: 12.5rem;
		position: absolute;
		height: 33.5vw;
		/* height: 67.75%; */
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		background-position: left center;
		background-repeat: no-repeat;
		background-size: contain;
		max-width: 107rem;
	}
	/* .explorer__steps__line{
		height: 0.5rem;
		position: absolute;
		width: 100%;
		transform: rotate(180deg);
		top: 0;
		left: 1.4rem;
		background: var(--grey-bg-50);
	} */
	.explorer__steps__line{
		/* height: 100%; */
		/* position: absolute;
		width: 0.5rem;
		top: 0;
		left: 1.4rem;
		background: var(--grey-bg-50); */
		
	}
	.explorer__step__linebox{
		background: var(--grey-bg-50);
		width: 0.5rem;
		left: 1.4rem;
		position: absolute;
		bottom: 0;
		height: 4.8rem;
		border-radius: 0.5rem;
		overflow: hidden;
		
	}
	progress {
		top: 0;
		-webkit-appearance: none;
		appearance: none;
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		height: 0;
		border: none;
		/* background: var(--grey-bg-50); */
		background: var(--blue-btn);
		z-index: 0;
	  }
	
	  progress::-webkit-progress-bar {
		background: transparent;
	  }
	  progress::-webkit-progress-value {
		background: var(--blue-btn);
		background-attachment: fixed;
	  }
	  progress::-moz-progress-bar {
		background: var(--blue-btn);
		background-attachment: fixed;
	  }
	  

}


/* ---------- explorer end  ------------------ */
/* ---------- intagration  ------------------ */

.intagration {
	padding: 15.7rem 0 18rem;

	ul{
		list-style: none;
		margin: 0;
	}
	.intagration__inner {
	}
	.intagration__top {
		max-width: 86.3rem;
		text-align: center;
		margin-bottom: 4rem;

		p{
			@include type-inter(1.8rem, 400, 1.78, 0);
			color: var(--grey-600);
			margin-bottom: 0;

		}
	}
	.container {
	}
	.subtitle {
	}
	.intagration__tabs {

	}
	.btn-show{
		margin-top: 5rem;
		
	}
	 .more{

	 }
}

.tabs {
	ul{
		list-style: none;
		margin: 0;
	}
	.navtab {
	}
	.tabs__menu {
		display: flex;
		width: calc(100% + 8rem);
		margin: 0 -4rem;
		justify-content: center;
	}
	.navtab__item {
		margin: 0 4rem;
		display: flex;
		align-items: center;
		margin-bottom: 5rem;
		a{
			@include type-rubik(1.6rem, 600, 1.375, 0.02rem);
			@include transition();
			color: var(--grey-800);
			padding: 1rem 0;

			&.active{
				color: var(--white);
				padding: 1rem 2.4rem;
				background: var(--grey);
				border-radius: 2rem;
			}
			
		}
	}
	.current {
	}
	.last {
	}
	.list-wrap {
	}
	.tabs__content {
		width: 100%;
		max-width: 100%;
		margin: 0 auto;
		text-align: center;
	}
	.hide {
	}
}
.tab__item{

	&.show{
		.intagration__rows {
			.intagration__row {
			
				&:nth-child(odd){
					animation: none;
				}
				&:nth-child(even){
					animation: none;
				}
				.intagration__items:not(:first-child){

					display: none;
				}
				.intagration__items{
					/* width: calc(100% + 1.6rem);
					margin: 0 -0.8rem; */
					justify-content: center;
					width: 100%;

					.intagration__item{
						width: calc(14.28% - 1.6rem);
						min-width: auto;

						h6{
							text-align: start;
						}
					}
				}
				
			}
		}
	}
}
.intagration__rows {
	width: 100%;
	/* margin: -8px 0; */
	padding: 0;
	overflow: hidden;
	position: relative;
	list-style: none;
	ul{
		list-style: none;
		margin: 0;
	}
	.intagration__row {
		/* &:not(:nth-child(1)){
			display: none;
		} */
		&:nth-child(odd){
			animation: 75s linear 0s infinite alternate none running intagration-animate;
		}
		&:nth-child(even){
			animation: 75s linear 0s infinite alternate none running intagration-animate2;
		}
		display: flex;
		
	}

	.intagration__items {
		margin: 0;
		display: flex;
		padding: 0;
		transform: translate3d(0px, 0px, 0px);
		list-style: none;
		transition: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1) 0s, opacity 0.8s cubic-bezier(0.77, 0, 0.175, 1) 0s;
		/* margin: -0.8rem;
		width: calc(100% + 1.6rem); */
	}
	.intagration__item {
		min-width: 27.1rem;
		height: 9.2rem;
		width: 100%;
		margin: 0.8rem;
		background: var(--white);
		border: 0.1rem solid var(--line);
		box-shadow: 0px 1rem 4rem -0.5rem rgba(9, 29, 46, 0.05);
		border-radius: 1.6rem;
		@include transition();
		&:active{
			border: 0.1rem solid var(--blue);
		
		}
		&:hover{
			border: 0.1rem solid var(--blue-btn);
		}
		a{
			width: 100%;
			display: flex;
			height: 100%;
			@include transition();
			&:hover{
				transform: scale(1.1);
			}
			&:active{
				transform: scale(1);
			
			}
		}

		.intagration__item__box {
			display: flex;
			padding: 2rem 2rem 2rem 3.6rem;
			width: 100%;
			height: 100%;
			align-items: center;
			& img{
				width: 5.2rem;
				height: 5.2rem;
				margin-right: 1.2rem
			}
			& h6{
				@include type-rubik(1.6rem, 600, 1.375, 0.02rem);
				color: var(--grey);
				margin-bottom: 0;
			}

		}
	}
	
	
}
@keyframes intagration-animate{
0% {
	transform: translateX(-50%) translateZ(0);
  }
  100% {
	transform: translateX(0) translateZ(0);
  }
}
@keyframes intagration-animate2{
0% {
	transform: translateX(0) translateZ(0);
  }
  100% {
	transform: translateX(-50%) translateZ(0);
  }
}
/* ---------- intagration end  ------------------ */
/* ---------- Benefits ------------- */

.benefits {
	background: var(--blue-btn);
	color: var(--white);
	h2{
		color: var(--white);
	}
	.benefits__top {
		padding: 7.7rem 0;
		position: relative;
		overflow: hidden;
		p{
			@include type-inter(2.4rem, 400, 1.5, 0);
			color: var(--grey-200);
			margin-bottom: 0;
		}
		.benefits__inner {
			display: flex;
		}
		.content__side {
			width: 50%;
			display: flex;
			align-items: center;
			&.left {
				
				.content{
					max-width: 80rem;
					width: 100%;
				}
			}
		}
		.left {
		}
		.content__items {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 62.9%;
			margin-left: auto;
			margin-right: 13.86%;
			position: relative;
			padding-top: 43.3%;
		}
		.content__item {
			width: 56.9%;
			position: absolute;
			height: 156.27%;
			top: 0;
			left: -4%;
			&:nth-child(2){
				left: 33%;
			}
			&:nth-child(3){
				right: -26.5%;
				left: auto;
			}
			
			& img{
				
			}
		}
	}
	.benefits__overlay {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
	}
	.benefits__inner {
		position: relative;
		z-index: 1;
	}
	.container {
	}
	.content__side {
	}
	.left {
	}
	.content {
	}
	.subtitle {
		color: var(--grey-300);
	}
	.right {
	}
	.content__items {
		display: flex;
		
	}
	.line{
		width: 100%;
		height: 0.1rem;
		background: var(--line);
		display: block;
		margin-bottom: 2.4rem;
	}
	
	.link{
		color: var(--white);

	}
	.benefits__bottom {
		padding: 12rem 0;

		h2{
			@include type-rubik(8rem, 400, 1.2, 0.05rem);
			margin-bottom: 2.4rem;
		}
		.content__items {
			width: calc(100% + 10rem);
			margin: -5rem;
		}
		.content__item {
			width: calc(33.33% - 10rem);
			margin: 5rem;

			.content__item-subtitle, p {
				@include type-inter(1.8rem, 400, 1.78, 0);
			}
			.content__item-subtitle{
				margin-bottom: 2.4rem;
			}
			p{
				color: var(--grey-200);
				margin-bottom: 4rem;
			}

		}
	}
	
}


/* ---------- Benefits endv------------- */
/* ---------- Prices ------------------ */
.price {
	padding: 18rem 0;

	h2{
		margin-bottom: 2rem;
	}
	.subtitle {
		color: var(--blue-btn);
	}
	.title {
		color: var(--blue-btn);
		
	}
	
	
	.big {
		@include type-rubik(2.4rem, 600, 1.38, 0.02rem);
		color: var(--grey-600);
		margin: 0 0.5rem;
		text-transform: uppercase;
		
	}
	.small {
		@include type-rubik(1.3rem, 700, 1.5, 0);
		color: var(--grey-600);
		text-transform: uppercase;

	}
	.field__value {
	}
	.btn {
	}
	.link {
	}
	.table__col__box__bussiness {
	}
	.table__col__box__enterprice {
	}
	.small-prev {
		@include type-rubik(1.3rem, 700, 1.38, 0);
		color: var(--grey-600);
		margin-right: 1rem;
		margin-top: auto;
		margin-bottom: auto;
		/* margin-bottom: 1rem; */
		text-transform: uppercase;
	}
}
.price__table{
	h3{
		margin-bottom: 1.6rem;
	}
	.text{
		@include type-inter(1.8rem, 400, 1.78, 0);
		margin-bottom: 1.6rem;
	}

	thead{
        th:not(:first-child){
			.title {
				display: inline-block;
				background: var(--grey-100);
				padding: 0.8rem 2.4rem;
				border-radius: 2rem;
				margin-bottom: 1.6rem;
			}
		}
		th{
			padding-bottom: 5.2rem;
		}

		.table__content{
			h6{
				@include type-inter(2.4rem, 400, 1.5, 0);
				margin-bottom: 1.2rem;

			}
			p.text{
				padding-right: 12%;
			}
		}
		
	}
	tr{
		
		th, td{
			text-align: center;
			vertical-align: middle;
		}
		img{
			width: 2.4rem;
			margin: 0 auto;
			/* display: block; */
			
		}
		th:first-child, td:first-child{
			width: 29.1%;
			text-align: start;

		}
	}
	tbody{
		tr{

			&:nth-child(odd){
				background: var(--grey-bg-200);
			}
			&:nth-child(even){

			}
			&.table__footer{
				background: none;
			}

			& td{

				&:first-child{
					@include type-rubik(1.6rem, 600, 1.375, 0.02rem);
					color: var(--grey);
				}
			}
		}
		td{
			padding: 2rem;
			text-align: center;

			@include type-rubik(1.8rem, 400, 1.44, 0);
			color: var(--grey-800);
			

		}
	}
	.botton__wrapper{
		display: flex;
		flex-direction: column;
	}
	h3{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.table__footer{

		td{
			padding-top: 7rem;
		}
	}
	.link {
		@include type-inter(1.8rem, 400, 1.44, 0);
		color: var(--grey-600);
	}
	.botton__box {
		text-align: center;

		.btn{
			max-width: 25rem;
			width: 100%;
		}
		&:first-child{
			margin-bottom: 2rem;
		}

	}
}
/* ---------- Prices endv------------- */
/* ---------- Prices 2------------- */
.price__lvl-two {
	position: relative;
	padding: 12rem 0;
	
	h2{
		color: var(--white);
		margin-bottom: 3.8rem;
	}
	.price__overlay {
		z-index: -1;
	}
	.price__inner {
		display: flex;
	}
	.container {
	}
	.content__side {
	}
	.left {
		width: 29.1%;
		padding-right: 2.2rem;
		color: var(--white);
		.content {
			max-width: 100%;
			width: 100%;
			
			p{
				max-width: 38.9rem;
				margin-bottom: 2rem;
				@include type-inter(1.8rem, 400, 1.78, 0);

			}
		}
		.link{
			color: var(--white);
		}
	}
	
	.right {
		width: 70.9%;
	}
	.price__items {
		display: flex;
		width: calc(100% + 2.2rem);
		margin: -1.1rem;
	}
	
}
.price__items__wrapper{
	
	&.mob{
		display: none
	}
}
.price__items__header{
		margin-bottom: 6rem;
		h6{
			@include type-inter(2.4rem, 400, 1.5, 0);
			margin-bottom: 1.2rem;

		}
		p.text{
		}
	
	h6{

	}
	.text {
	}
	.title {

	}
}
.price__items_lvl2{
	.price__item li::before {
		display: none;
	  }
	  display: flex;
	  .price__item {
			
			li:nth-child(odd){
				background: var(--grey-bg-200);
				border-radius: 0.8rem;
				
			}
			p{
				margin-bottom: 0;
			}
			.content__item-top {
				margin-bottom: 4rem;
			  }
			.content__item-center{
				background: none;
				padding: 0;
				width: 100%;
				margin: 0;
				ul{
					width: 100%;
				}
				li{
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 1.3rem 2rem;
				}
				.value__row{
					width: 30%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.title__row{
					width: 70%;
					padding-right: 1rem;
					@include type-rubik(1.4rem, 600, 1.71, 0.02rem);
					color: var(--grey);
				}
				img{
					width: 2.4rem;
					height: 2.4rem;
				}
				
			}
	  }
	 
}
.price__item {
	display: flex;
	flex-direction: column;
	width: calc(33.33% - 2.2rem);
	margin: 1.1rem;
	padding: 4rem;
	background: var(--white);
	box-shadow: 0rem 1rem 4rem -0.5rem rgba(0, 99, 181, 0.05);
	border-radius: 0.8rem;

	.title {
		color: var(--blue-btn);
		display: inline-block;
		background: var(--grey-100);
		padding: 0.8rem 2.4rem;
		border-radius: 2rem;
		margin-bottom: 1.6rem;

	}
	.content__item-top {
		text-align: center;
	}
	
	.small-prev {
	}
	.big {
	}
	.small {
	}
	h3{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.big {
		@include type-rubik(2.4rem, 600, 1.38, 0.02rem);
		color: var(--grey-600);
		margin: 0 0.5rem;
		text-transform: uppercase;
		
	}
	.small {
		@include type-rubik(1.3rem, 700, 1.5, 0);
		color: var(--grey-600);
		text-transform: uppercase;

	}
	.small-prev {
		@include type-rubik(1.3rem, 700, 1.38, 0);
		color: var(--grey-600);
		margin-right: 1rem;
		text-transform: uppercase;
	}
	.content__item-center {
		background: var(--grey-bg-50);
		width: calc(100% + 8rem);
		margin: 0 -4rem;
		padding: 3.2rem 3.5rem 3.2rem 4rem;
		display: flex;
		flex: 1;
	}
	.content__item-bottom {
		padding-top: 4rem;
	}
	.botton__wrapper {

		.btn{
			max-width: 25rem;
			width: 100%;
		}
	}
	.title{
		font-size: 1.6rem;
		line-height: 1.5;
	}
	.botton__box {
		text-align: center;

		&:first-child{
			margin-bottom: 2rem;
		}

	}
	ul{
		list-style: none;
		margin-left: 0;
	}
	li{
		@include type-inter(1.6rem, 400, 1.75, 0);
		color: var(--grey-600);
		margin-bottom: 1.2rem;
		padding-left: 3.4rem;
		&:last-child{
			margin-bottom: 0;
		}

		&::before{
			content: '';
			display: inline-block;
			background-image: url('img/check-circle-fill.png');
			position: absolute;
			left: 0;
			top: 0.2rem;
			width: 2.4rem;
			height: 2.4rem;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;

		}
	}
	.btn {
		
	}
	.link {
		@include type-inter(1.6rem, 400, 1.75, 0);

	}
}

/* ---------- Prices end------------- */
/* ---------- Customer ------------- */
.customer {
	padding: 16.4rem 0 17.4rem;
	.customer__inner {

	}
	h2{
		margin-bottom: 8rem;
	}
	.container {
	}
	.content__side__wrapper {
		display: flex;
		margin-bottom: 4rem;
		position: relative;
	}
	.content__side {
	}
	.left {
		width: 24.94%;
	}
	.right {
		width: 75.06%;
		padding: 8rem 11.7rem 8rem 6.4rem;

	}
	.subtitle {
	}
	.customer__slider__nav {
	}
	.slider__nav {
	}
	.nav__prev {
	}
	.nav__next {
	}
	
	.customer__slider {
	}
	.customer__slide {
	}
	.customer__slide__inner {
		display: flex;
	}
	.content__slider__left {
		width: 27.32%;
	}
	.customer__img__wrapper {
		max-width: 16rem;
		display: flex;
		margin-bottom: 3.3rem;
	}
	.customer__img {
		width: 8.5rem;
		height: 8.5rem;
		border-radius: 50%;
		filter: drop-shadow(0 0.4rem 1rem rgba(206, 212, 222, 0.5));
		img{
			border-radius: 50%;
			height: 100%;
		}
	}
	.customer__img__logo {
		margin-right: -1.1rem;
		z-index: 1;
	}
	.customer__img__author {

	}
	.customer__author__name {
		@include type-rubik(1.8rem, 700, 1.33, 0.02rem);
		color: var(--grey);

	}
	.customer__company {
		@include type-inter(1.6rem, 400, 1.5, 0);
		color: var(--grey-600);
		
	}
	.content__slider__right {
		width: 72.68%;

		p{
			@include type-inter(1.8rem, 400, 1.78, 0);
			margin-bottom: 0;
			color: var(--grey-600);
			
		}
	}
	.button__wrapper {
		text-align: center;
	}
	.btn-user {
	}
}

/* ---------- Customer end------------- */
/* ---------- Section nav------------- */

.section__nav {
	&.lvl2{
		background: var(--grey-bg-th);
	}
	.section__nav__title {
		text-align: center;
		padding: 9rem 0;

		
	}
	.section__nav__inner{
		display: flex;
		z-index: 1;
		position: relative;
	}
	h6{
		color: var(--white);
	}
	.content__side {
		width: 50%;
		position: relative;
		padding: 5rem 0;
		min-height: 22.3rem;
	}
	.section__nav__left {
		.overlay {
			background-position: right center;
		}
		.content{
			margin-left: auto;
		}
	}
	.overlay {
		z-index: -1;
	}
	.content {
		max-width: 96rem;
		width: 100%;
		padding: 0 3rem;
		text-align: center;
	}
	.btn-tr-arrow {
	}
	.section__nav__right {
		.overlay {
			background-position: left center;
		
		}
		.content{
			margin-right: auto;
		}
	}
}


/* ---------- Section nav end------------- */

/* --------- footer ---------------------- */

.footer {
	padding: 12rem 0 7.8rem;
	background: var(--grey);
	color: var(--white);
	position: relative;
	.container{
		max-width: 169rem;
	}
	h5, h6{
		color: var(--white);
	}
	h5{
		@include type-rubik(1.6rem, 600, 1.375, 0);
		text-transform: uppercase;
		margin-bottom: 2rem;
	}
	h6{
		@include type-rubik(1.1rem, 700, 1.45, 0);
		color: var(--grey-200);
		text-transform: uppercase;
		margin-bottom: 0;
	}
	ul{
		list-style: none;
		margin-left: 0;
	}
	p{

		&.copyright{
			color: var(--grey-4);
		}
		&.text{
			color: var(----grey-6);

			a{
				color: var(----grey-6);

				&:hover{
					color: var(--blue-100);
				}
				&:active{
					color: var(	--blue-200);
		
				}
			}
		}
	}
	a{
		color: var(--white);

		&:hover{
			color: var(--blue-100);
		}
		&:active{
			color: var(	--blue-200);

		}
	}
	.footer__inner {
		display: flex;
	}
	.content__side {
	}
	.left {
		width: 22.09%;
		@include type-inter(1.4rem, 400, 1.71, 0);
		padding-right: 4rem;

	}
	.center{
		width: 58.83%;
		
	}
	.footer__bottum__info{

		p{
			margin-bottom: 0;
		}
	}
	.right {
		width: 19.08%;
	}
	.content {
	}
	.logo {
		max-width: 15rem;
		margin-bottom: 3.7rem;
	}
	.footer__bottum__info{
		margin-bottom: 6rem;
	}
	
	.center {
	}
	.footer__menu {
		display: flex;
		width: calc(100%);
		
	}
	.menu__items {
		width: calc(25% - 4rem);
		margin-right: 2rem;
		margin-bottom: 0;
	}
	.menu__item {
		margin-bottom: 2rem;

		&:first-child{
			@include type-rubik(1.6rem, 600, 1.375, 0);
			text-transform: uppercase;
		}
			@include type-inter(1.3rem, 400, 1.54, 0);
	
		&:last-child{
			margin-bottom: 0;
		}
	}
	
	.contact_items {
		
	}
	.contact_item {
		margin-bottom: 2.8rem;
		&:last-child{
			margin-bottom: 0;
		}
	}
	.contact__mail {
	}
	.items {
	}
	.item {
		margin-bottom: 2rem;

		a{
			color: var(--blue-200);
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
	.contact__address {
	}
	.contact__phone {
		h5{
			margin-bottom: 1rem;
		}
		a{
			@include type-inter(1.8rem, 400, 1.78, 0);

		}
	}
}
.social {

	.social__items {
		display: flex;
	}
	.social__item {
		width: 3.5rem;
		margin-right: 2.2rem;
		&:last-child{
			margin-right: 0;
		}
	}
}


/* --------- footer end ------------------ */

/* ------------------------------------------- ABOUT page------------------------------------------------------ */

/* --------- intro template ----------------------- */

.intro__template {
	position: relative;
	padding: 10rem 0;
	min-height: 61.6rem;
	color: var(--white);
	display: flex;
	align-items: center;
	.overlay {
		position: absolute;
	}
	h1{
		color: var(--white);
	}
	.intro__template__inner {
		max-width: 81.7rem;
		z-index: 1;
		position: relative;
		text-align: center
	}
	p{
		@include type-inter(2.4rem, 400, 1.5, 0);
		margin-bottom: 0;
	}

	.container {

	}
}


/* --------- intro template end ----------------- */
/* --------- company --------------------- */

.company {
	padding: 18rem 0;

	.company___inner {

	}
	.row {
	}
	.container {
	}
	.content__side {
	}
	.left {

		h6{
			@include type-inter(2.4rem, 400, 1.5, 0);
			margin-bottom: 1.2rem;

		}	
		p{
			margin-bottom: 2.4rem;
			@include type-inter(1.8rem, 400, 1.78, 0);
			color: var(--grey-600);
		}
		.content__inner {
			max-width: 83.86%;
		}
	}
	.content__inner {
	}
	.subtitle {
	}
	.founders {
		display: flex;
		align-items: center;
	}
	.founders__img {
		display: flex;
		max-width: 15.6rem;
		width: 100%;
		margin-right: 1.6rem;

	}
	.founders__img_item {
		width: 8.5rem;
		height: 8.5rem;
		border-radius: 50%;
		overflow: hidden;

		&:first-child{
			margin-right: -1.2rem;
		}
	}
	.founders__text {
		@include type-inter(1.6rem, 600, 1.25, 0.02rem);
		color: var(--grey-600);

	}
	.right {
		padding-left: 1.5rem;
		.content__inner {
			width: 75.42%;
		}
	}
	.content__items {
	}
	.content__item {
		h6{
			margin-bottom: 1.6rem;
		}
		margin-bottom: 6rem;
		p{
			margin-bottom: 0;
			@include type-inter(1.8rem, 400, 1.78, 0);
			color: var(--grey-500);
		}
		:last-child{
			margin-bottom: 0;
		}
	}
}


/* --------- company end ----------------- */
/* --------- why ------------------------- */

.why {
	padding: 11.7rem 0 ;
	min-height: 69.2rem;
	position: relative;
	color: var(--white);
	.overlay {

		&.mob{
			display: none;
		}
	}
	.why__inner {
		position: relative;
		z-index: 1;
	}
	.container {
	}
	.content__header {
		margin-bottom: 6rem;
		max-width: 69.8rem;

		p{
			@include type-inter(1.8rem, 400, 1.78, 0);
			margin-bottom: 0;
			max-width: 53.9rem;
		}
		h2{
			color: var(--white);
		}
		.subtitle{
			color: var(--grey-200);
		}
	}
	.subtitle {
	}
	.why__items {
		display: flex;
		justify-content: space-between;
		width: calc(100% + 13.4rem);
		margin: -6.7rem;

	}
	.why__item {
		margin: 3rem;
		width: calc(33.33% - 13.4rem);
		margin: 6.7rem;
		/* justify-content: center; */
		display: flex;
		.why__item__inner{
			width: 100%;
		}
		&:last-child{
			/* justify-content: flex-end; */

		}
		&:first-child{
			/* justify-content: flex-start; */

		}
		h3{
			@include type-rubik(8rem, 400, 1.2, 0.05rem);
			color: var(--white);
			display: flex;
			align-items: center;
			span:not(.counter-value){
				@include type-rubik(1.6rem, 600, 1.375, 0);
				text-transform: uppercase;
				margin-left: 2rem;
			}
		}
		p{
			margin-bottom: 2.4rem;
		}
		.line{
			display: block;
			width: 100%;
			max-width: 100%;
			height: 0.1rem;
			background: var(--grey-100);
		}
	}
	.line {
	}

}

/* --------- why end --------------------- */
/* --------- Files & Links --------------- */

.files {
	background: var(--blue-btn);
	padding: 14.2rem 0 11.3rem;

	.files__inner {
	}
	.container {
	}
	.content__header {
		margin-bottom: 5.5rem;

		h2{
			color: var(--white);

		}
		.subtitle{
			color: var(--grey-200);

		}
	}
	.subtitle {

	}
	.files__items {
		display: flex;
		width: calc(100% + 4.8rem);
		margin: -2.4rem;

	}
	.files__item {
		width: calc(33.33% - 4.8rem);
		padding: 4rem;
		margin: 2.4rem;
		background: var(--white);
		box-shadow: 0rem 1.5rem 4.5rem rgba(99, 115, 129, 0.2);
		border-radius: 0.8rem;

		p{

			@include type-inter(1.8rem, 400, 1.78, 0);
			color: var(--grey-600);
			margin-bottom: 0;

		}
	}
	h6{
		margin-bottom: 1.6rem;
	}
	.files__item_icon {
		width: 4.2rem;
		margin-bottom: 2.4rem;


	}
}


/* --------- Files & Links end ----------- */
/* --------- contact --------------------- */

.contact {
	padding: 18.5rem 0 17.2rem; 


	&.about__contact{
		padding-top: 0;
	}
	h2{
	}
	h6{
		text-transform: uppercase;
	}
	.contact__inner {

	}
	.container {
	}
	.row {
	}
	.content__side {
	}
	.left {
		.content__inner {
			width: 79.3%;

		}
	}
	.content__inner {
	}
	.content__header {
		max-width: 54.4rem;
		margin-bottom: 4rem;
		p{
			@include type-inter(1.8rem, 400, 1.78, 0);
			color: var(--grey-600);
			margin-bottom: 0;
		}
	}
	.subtitle {
	}
	.contact__items {
		display: flex;
		flex-wrap: wrap;
		width: calc(100% + 6.4rem);
		margin: -1rem -3.2rem;
	}
	.contact__item {
		width: calc(49.99% - 6.4rem);
		margin: 1rem 3.2rem;

		h6{
			text-transform: uppercase;
		}
	}
	.contact__item__box {
		margin-bottom: 2.4rem;
		&:last-child{
			margin-bottom: 0;
		}
		.icon {
			width: 4rem;
			height: 4rem;
			min-width: 4rem;
			border-radius: 50%;
			background: var(--grey-bg-50);
			margin-right: 2.4rem;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: -0.6rem;
			
			img{
				width: 1.4rem;
				height: 1.4rem;
			}

		}
		a{
			padding-top: 0.6rem;
			display: flex;
			@include type-inter(1.6rem, 400, 1.75, 0);
			color: var(--grey-800);

		}

		.text__link {
			display: flex;
			flex-direction: column;
			margin-top: -0.6rem;

		}
		.title__link {
			@include type-rubik(1.1rem, 700, 1.33, 0.02rem);
			color: var(--grey-600);
			text-transform: uppercase;
			margin-bottom: 0.3rem;
		}
		.text__link {
		}
	}
	
	.right {
		padding-left: 2rem;

		.content__inner {
			width: 84.34%;

		}
	}
	.message__form {
	}
	h4{
		margin-bottom: 3rem;
	}
	.contact__items__wrapper{
		display: flex;
		width: calc(100% + 5rem);
			margin: -2.5rem;
		.contact__items__col{
			display: flex;
			flex-direction: column;
			width: calc(50% - 5rem);
			margin: 2.5rem;
			.contact__items{
				flex-direction: column;
				width: calc(100%);
				margin: -1.6rem 0 3.8rem;

				&:last-child{
					margin-bottom: 0;
				}

			}
			.contact__item{
				width: 100%;
				margin: 1.6rem 0;
			}

		}

	}
	.social{
		img{
		}
	}

	h6{
		@include type-rubik(1.6rem, 600, 1.375, 0.02rem);
		color: var(--grey);
		font-family: 'Rubik';
	
	}
	
}
form{

	.fields__wrapper {
	}
	.form__field {
	}
	.form__item {
		max-width: 100%;
		width: 100%;
		background: var(--grey-bg-50);
		border-radius: 0.8rem;
		margin-bottom: 3.6rem;
		position: relative;
		@include transition();
		&.form__item__optional{
			input{
				
				padding: 1.8rem 8.8rem 1.8rem 2.3rem;
				
			}
		}
		&.focus{
			label{
				left: 2.3rem;
				top: -2.8rem;
			}
			/* border: 0.1rem solid var(--blue-btn); */
			
		}	
		
		input{
			width: 100%;
			height: 5.6rem;
			padding: 1.8rem 2.3rem;
			@include type-inter(1.4rem, 500, 1.43, 0);
			color: var(--grey-500);
			z-index: 1;
			position: relative;
		}
		
		textarea{
			width: 100%;
			padding: 1.8rem 2.3rem;
			min-height: 13.2rem;
			@include type-inter(1.4rem, 500, 1.43, 0);
			color: var(--grey-500);
			z-index: 1;
			position: relative;
		}
		label{
			position: absolute;
			left: 2.3rem;
			top: 1.8rem;
			@include type-inter(1.4rem, 500, 1.43, 0);
			color: var(--grey-500);
			z-index: 0;
			@include transition();
		}
		.optional{
			position: absolute;
			right: 2.3rem;
			top: 50%;
			transform: translateY(-50%);
			@include type-inter(1.4rem, 400, 1.43, 0);
			color: var(--grey-400);
			z-index: 0;
		}
	}

	.ph {
	}
	.form__label {
	}
	.submit__wrapper {
	}
	.btn {
		max-width: 100%;
		width: 100%;
	}
}


/* --------- contact end ----------------- */



/* ------------------------------------------- ABOUT page END --------------------------------------------------- */
/* ------------------------------------------- DEMO page -------------------------------------------------------- */

/* --------- contact --------------------- */

.iti.iti--allow-dropdown{
	width: 100%;
}
.iti__flag-container {
	
	padding-left: 1.3rem;
	padding-right: 1.3rem;
  }
 
  .iti--separate-dial-code .iti__selected-flag {
	background: none !important;
	/* position: relative; */
  }
  .iti__selected-flag {
	padding-right: 2.5rem;
  }
  .iti--separate-dial-code .iti__selected-flag::after {
	content: '';
	background: #DAE5F7;
	position: absolute;
	width: 0.1rem;
	height: 2.25rem;
	right: 1.7rem;
	top: 50%;
	transform: translateY(-50%);

  }
  .iti--separate-dial-code .iti__selected-dial-code {
	font-weight: 400;
	font-size: 1.4rem;
	line-height: 1.71;
	color: #848F9E;
  }
  .iti__arrow {
	margin-left: 0.2rem;
	width: 2.4rem;
	height: 2.4rem;
	border: 0 !important;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-image:url(img/arrows.png);
  }
/*   .iti__flag-container {
	padding-left: 3rem;
	padding-right: 3rem;
  }
  .iti--separate-dial-code .iti__selected-flag {

	transform: translateX(-1.7rem);
  } */
 /*  .iti--allow-dropdown input{
	padding-left: 6.5rem !important;
  } */
  .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
	background: none !important;
  }
  .contact.demo{
	p{
		@include type-inter(2.4rem, 400, 1.5, 0);
		color: var(--grey);
	}
	.content__header{
		max-width: 60.4rem;
		p {
			font-family: "Inter", sans-serif;
			font-size: 2.4rem;
			line-height: 1.5;
			color: var(--grey-600);
		}
	}
	.iti__country-list{

		li{
			padding-left: 35px;
		}
	}
	ul{
		list-style: none;
		margin-left: 0;
		margin-bottom: 4rem;
	}
	ul:not(.iti__country-list){
		
		li{
			margin-bottom: 1.6rem;
			padding-left: 5.2rem;
			position: relative;
			span{
				top: 0.4rem;
				left: 0;
				position: absolute;
				width: 2rem;
				height: 2rem;
				display: inline-block;
			}
			img{

			}
			&:last-child{
				margin-bottom: 0;
			}
		}
	}

  }

/* --------- contact end ----------------- */
/* --------- offer ---------------------- */

.offer {
	position: relative;
	min-height: 43.7rem;
	padding: 12rem 0;
	display: flex;
	align-items: center;
	.offer__inner {
		position: relative;
		z-index: 1;
	}
	h2{
		color: var(--white);
		margin-bottom: 0;
	}
	h6{
		color: var(--white);
		margin-bottom: 0;

	}
	.container {
	}
	.row {
	}
	.content__side {
	}
	.left {
		width: 28.98%;
		padding-right: 2rem;
	}
	.content__inner {
	}
	.subtitle {
		color: var(--white);
	}
	.right {
		width: 71.02%;
	}
	.offer__items {
		display: flex;
		justify-content: space-between;
		width: calc(100% + 4rem);
		margin: -2rem;
	}
	.offer__item {
		width: calc(100% - 4rem);
		margin: 2rem;
		display: flex;
		flex-direction: column;
	}
	.offer__item__icon {
		width: 3.6rem;
		min-width: 3.6rem;
		margin-bottom: 1.2rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
}

/* --------- offer end ----------------- */

/* ------------------------------------------- DEMO page END ---------------------------------------------------- */
/* ------------------------------------------- CONTACT page-------------------------------------------------------- */

/* --------- contact --------------------- */
.contact{
	&.contact__sales{

		.content__header {
			max-width: 62.1rem;
			margin-bottom: 6rem;
		  }
		

			.contact__item__box a{
				color: var(--grey);

			}

	
	
		.locations {
			
			a{
				padding-top: 0 !important;
				@include type-inter(2.4rem, 400, 1.5, 0);
				color: var(--blue-btn);
				position: relative;

				&:hover{
					color: var(--blue-300);
				}
				&:active{
					color: var(--blue);
				}
				&::after{
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					max-width: 9.5rem;
					height: 0;
					width: 100%;
					border-top: 0.1rem dashed #000000;
				}
				margin-bottom: 2rem;

			}
		}
	}
}







.map{
	position: relative;
	padding-top: 86%;
  }
  #map {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	border-radius: 1rem;

}
#map .gmnoprint{
	display: none;
}
#map img[alt='Google']{
	display: none;
}
#map .gm-style-cc{
	display: none;
}
#map .gm-control-active.gm-fullscreen-control{
	display: none;
}
.location__map {
	max-width: 23.3rem;
}
.locations {
	padding-top: 2.4rem;
	padding-left: 6.4rem;
	
}
.map {
}


/* --------- contact end ----------------- */


/* ------------------------------------------- CONTACT page END ---------------------------------------------------- */
/* ------------------------------------------- Data Rep Meth page UX -------------------------------------------------------- */

/* --------- intro ---------------------- */
.intro__template{

	&.template-2{

		.intro__template__inner{
			max-width: 128.1rem;
		}
	}
} 


/* --------- intro end ------------------- */

/*---------- right method --------------------- */

	.right__method {
		padding: 17.2rem 0 8.8rem;
		.right__method__inner {
		}
		.container {
		}
		.right__method__top {
			max-width: 129.5rem;
			margin-bottom: 16.6rem;
			h2{
			}
			p{
				@include type-inter(1.8rem, 400, 1.78, 0);
				color: var(--grey-600);
				
			}
			.content {
				display: flex;
			}
			.content__side {
			}
			.left {
				width: 46.25%;
				padding-right: 5rem;
			}
			.content__inner {
			}
			.subtitle {
			}
			.righr {
				width: 53.75%;
			}
		}
	
		.right__method__bottom {

			
			
		}
		h3{
			text-align: center;
		}
		table{
			border-radius: 2rem;
			overflow: hidden;
			.title{
				display: none;

				@include type-rubik(1.8rem, 700, 1.44, 0.02rem);
				margin-bottom: 1.2rem;
				color: var(--grey);

				
			}
			tr{
				td:first-child{

					.title{
						@include type-rubik(1.4rem, 600, 1.71, 0.02rem);
						margin-bottom: 0rem;
						color: var(--grey-800);
						
					}
				}

			}

			.table__box {

			}
			h6{
				@include type-rubik(1.8rem, 700, 1.33, 0.02rem);
				color: var(--grey);
				margin-bottom: 1rem;
			}
		}
		thead{
			background: var(--grey-bg-th);
			
			tr{
			}
			th{
				text-align: start;
				padding: 2.4rem 6.7rem 2.4rem 5.7rem;
				color: var(--grey);
				
				&:first-child{
					width: 27.6%;
					@include type-inter(2.4rem, 400, 1.5, 0);
				}
				&:not(:first-child){
					@include type-rubik(2.4rem, 600, 1.5, 0.02rem);

				}
				&:nth-child(2){
					width: 36.2%;

				}
				&:nth-child(3){
					width: 36.2%;
				}
			}
		}
		tbody{
			background: var(--grey-bg-200);
			@include type-inter(1.8rem, 400, 1.78, 0);
			color: var(--grey-600);
			tr{
				border-bottom: 0.2rem solid var(--grey-bg-th);

				&:last-child{
					border-bottom: 0;
				}
			}

			td{
				border-right: 0.2rem solid var(--grey-bg-th);
				padding: 4rem 6.7rem 4rem 5.7rem;
				&:last-child{
					border-right: 0;
				}
				&:nth-child(3){
					padding-right: 8.8rem;
				}
			}
			p{
				margin-bottom: 0;
			}

			
			
		}
		ul{
			list-style: none;
			margin-left: 0;
			margin-bottom: 0;
			li{
				margin-bottom: 0.8rem;
				position: relative;
				padding-left: 1.4rem;
				&:last-child{
					margin-bottom: 0;
				}

				&::before{
					content: '';
					width: 0.4rem;
					height: 0.4rem;
					position: absolute;
					background: var(--blue-btn);
					border-radius: 50%;
					top: 1.4rem;
					left: 0;
				}
			}
		}
	}


/*---------- right method end --------------------- */

/* ------------------------------------------- Data Rep Meth UX page end----------------------------------------------------- */
/* ------------------------------------------- Snowflake page --------------------------------------------------------------- */


/* ---------------- contact ----------------------- */

.contact {
	&.get__in__touch {

		.contact__inner {
		}
		.container {
		}
		.row {
		}
		.content__side {
			&.left {
				width: 40.48%;
				padding-right: 4.8rem;
	
			}
			&.right {
				width: 59.52%;
				padding-left: 4.8rem;
			}

		}
		
		.content__inner {
			width: 100%;
		}
		.content__header {
			max-width: 62.4rem;
			margin-bottom: 5.4rem;
			p{
				@include type-inter(2.4rem, 400, 1.5, 0);



			}
		}
		.title__items {

			@include type-inter(1.6rem, 600, 1.25, 0.02rem);
			color: var(--grey);
			padding-bottom: 2rem;
			border-bottom: 1px solid var(--grey-500);
			display: block;
			margin-bottom: 2rem;
		}
		ul{
			list-style: none;
			margin: 0;

			li{
				@include type-inter(1.8rem, 400, 1.78, 0);
				margin-bottom: 1.2rem;

				&:last-child{
					margin-bottom: 0;
				}
				
			}
		}
		.link__items {


		}
		
		.message__form {

		}
		.form__row {
			display: flex;
			flex-direction: column;
		}
		.form__col {
			width: 100%;

			&.top{
				display: flex;
				width: calc(100% + 2rem);
				margin: -1rem;
				.fields__wrapper {
					margin: 1rem;
					width: calc(50% - 2rem);

					&:last-child{
						/* min-height: 100%;
						height: 100%; */
						display: flex;
						align-items: stretch;
						textarea{
							height: 100%;
							min-height: 100%;
						}
					}
					& .form__item:last-child{
						margin-bottom: 0rem;
					}
				}
				.form__item {

					
				}
			}
			&.bottom{
				display: flex;
				width: calc(100% + 2rem);
				margin: -1rem;
				padding-top: 2rem;
				.form__field{
					margin: 1rem;
					width: calc(50% - 2rem);
				}
			}
		}
		
		.form__field {
		}
		
		.ph {
		}
		.form__label {
		}
		.optional {
		}
		.checkbox__form {
		}
		.submit__wrapper {
		}
		.btn {
		}

	}
	
}

.custom-checkbox {
	position: absolute;
	z-index: -1;
	opacity: 0;
  }

  .custom-checkbox+label {
	display: inline-flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
	font-size: 1.8rem;
	line-height: 1.78;
	color: var(--grey-500);
  }
  .custom-checkbox+label::before {
	content: '';
	display: inline-block;
	width: 1.8rem;
	height: 1.8rem;
	flex-shrink: 0;
	flex-grow: 0;
	border: 0.2rem solid var(--grey-800);
	border-radius: 0.4rem;
	margin-right: 1.3rem;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%;
  }
  .custom-checkbox:checked+label::before {
	border-color: var(--blue-btn);
	background-color: var(--blue-btn);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }
.custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
	border-color: var(--blue-200);
  }
  .custom-checkbox:not(:disabled):active+label::before {
	background-color: var(--blue-200);
	border-color: var(--blue-200);
  }
  .custom-checkbox:focus+label::before {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .custom-checkbox:focus:not(:checked)+label::before {
	border-color: var(--blue-200);
  }
  .custom-checkbox:disabled+label::before {
	/* background-color: #e9ecef; */
  }


/* ---------------- contact end ------------------- */

/* ---------------- how works ------------------- */


.how__works {
	position: relative;
	
	/* padding: 7rem 0; */
	/* display: flex;
	align-items: center; */

	&::after{
		/* content: ''; */
		background-color: green;
		position: absolute;
		top: 0;
		left: auto;
		right: 0;
		bottom: 0;
		/* width: calc(50% + 36rem); */
		/* width: 68.75%; */
		height: 100%;
	}
	&::before{
		/* content: ''; */
		background-color: red;
		position: absolute;
		top: 0;
		left: 0;
		right: auto;
		bottom: 0;
		/* width: calc(50% - 36rem); */

		/* width: 31.25%; */
		height: 100%;
	}
	background: var(--blue-btn);
	.how__works__inner {
		display: flex;
		height: 100%;
		min-height: 57.9rem;
		z-index: 2;
		position: relative;
	}
	.content__side {
		position: relative;

		.overlay{
			background-position: right center;
		}
	}
	.left {
		width: 31.25%;
		padding-right: 8.2rem;
		color: var(--white);

		&::after{
			content: '';
			background-image: url('img/How-Works-bg-mob.png');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center right;
			position: absolute;
			top: 0;
			left: auto;
			right: 0;
			bottom: 0;
			width: 100vw;
			/* width: 68.75%; */
			height: 100%;
			z-index: -1;
		}
		h2{
			color: var(--white);
		}
		.content__inner {
			max-width: 38.8rem;
			width: 100%;

		}
		p{
			@include type-inter(2.4rem, 500, 1.5, 0);
			margin-bottom: 0;
		}

	}
	.content__inner {

		padding: 12rem 0 10.5rem;
	}
	.right {
		width: 68.75%;
		padding-left: 13rem;


		&::after{
			content: '';
			
			position: absolute;
			top: 0;
			left: 0;
			right: auto;
			bottom: 0;
			width:100vw;
			/* width: 68.75%; */
			height: 100%;
			z-index: -1;
		}

		.content__inner {

			max-width: 81.2rem;
		}

		p{
			@include type-inter(1.8rem, 400, 1.78, 0);
			color: var(--grey-50);
			margin-bottom: 3.2rem;

			&:last-child{
				margin-bottom: 0;
			}
		}
		h6{
			margin-top: 7.2rem;
			color: var(--grey-50);
			@include type-rubik(2.4rem, 500, 1.5, 0);
			margin-bottom: 0;
		}
	}

}


/* ---------------- how works end ------------------- */
/* ---------------- What We Offer ------------------- */


.what__offer {
	padding: 18rem 0 8.8rem;
	background: var(--grey-bg-th);
	.what__offer__inner {
		max-width: 148rem;
	}
	.container {
	}
	.what__offer__header {
		margin-bottom: 5.6rem;
		text-align: center;
	}
	.subtitle {
	}
	.what__offer__items {
		width: calc(100% + 2rem);
		margin: -1.4rem -1rem;
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
	}
	h3{
		margin-top: 16.3rem;
		text-align: center;
		
	}
	.what__offer__item {
		width: calc(49.99% - 2rem);
		margin: 1.4rem 1rem ;
		background: var(--white);
		box-shadow: 0px 1rem 4rem -0.5rem rgba(9, 29, 46, 0.05);
		border-radius: 0.8rem;
		padding: 4rem;

		h6{
			margin-bottom: 0.8rem;
		}
		p{

			@include type-inter(1.8rem, 400, 1.78, 0);
			color: var(--grey-600);
			font-family: 'Inter';
			margin-bottom: 0;
		}
	}
	.icon {
		margin-bottom: 2.4rem;
		width: 4.8rem;
		min-width: 4.8rem;
		
	}
	
}


/* ---------------- What We Offer end --------------- */




/* ------------------------------------------- Snowflake page end ------------------------------------------------------------- */
/* -------------------------------------------  Comparison page------------------------------------------------------------- */

	/*------------ comparison ---------------------- */
	.comparison {
		padding: 11.7rem 0 6.7rem;

		.mob{
			display: none;
		}
		h4{
			max-width: 98.2rem;
			width: 100%;
			margin: 0 auto 8rem;
			text-align: center;
		}
		
		table{
			width: 100%;
			
			img{
				width: 2.4rem;
				margin: -0.5rem auto;
			}
			.title__blue{
				color: var(--blue);
			}
			tr{
				
				
				&:first-child{

					td{
						vertical-align: middle;
						&:nth-child(2){
							h6{
								color: var(--grey);

							}
						}
						h6{
							color: var(--grey-600);
						}
					}

				}
				
				h6{
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					color: var(--grey);
					margin-bottom: 0;
					span{
					@include type-rubik(1.1rem, 700, 1.45, 0);
						text-transform: uppercase;
						white-space: nowrap ;

					}
				}
				td{
					padding: 2rem;
					&:first-child{
						padding-left: 2rem;
						text-align: start;

						h6{
							text-align: start;
						}
					}
				}
				
				th{
					padding: 2rem;
					@include type-rubik(1.6rem, 600, 1.375, 0.02rem);
					color: var();
				
					&:first-child{
						text-align: start;
						width: 27.53%;
					}
				}
				
			}
			td{
				text-align: center;
				/* vertical-align: middle; */
			}
			thead{
				tr{
					background: var(--grey-bg-300);
					position: relative;
					&::after{
						content: '';
						background: var(--grey-bg-300);
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
						top: 0;
						bottom: 0;
						right: 0;
						width: 1000vw;
						height: 100%;
						z-index: -1;
					}
				}
				
				
			}
			tbody{
				tr{
					position: relative;

					&:nth-child(odd){
					background: var(--grey-bg-200);
						&::after{
							content: '';
							background: var(--grey-bg-200);
							position: absolute;
							left: 50%;
							transform: translateX(-50%);
							top: 0;
							bottom: 0;
							right: 0;
							width: 1000vw;
							height: 100%;
							z-index: -1;
						}
					}
					&:nth-child(3){

					}
				}

			}
		}
		.title-1{
			@include type-inter(2.4rem, 400, 1.5, 0);
			color: var(--grey);

		}
		.title-2{
			@include type-inter(1.8rem, 500, 1.55, 0);
			color: var(--grey);

		}
		.body__head {
			/* 	background: var(--grey-bg-300); */
				position: relative;
				/* border-top: 6rem solid var(--white); */

				td{
					padding-top: 8rem;
				}
				&::after{
					border-top: 6rem solid var(--white);

					content: '';
					background: var(--grey-bg-300);
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					top: 0;
					bottom: 0;
					right: 0;
					width: 1000vw;
					height: 100%;
					z-index: -1;
				}
			
		}
		.comparison__inner {
		}
		.comparison__top {
		}
		.container {
		}
		.comparison__center {
		}
		.content {
		}
		.comparison__table {
		}
		.accordion {
		}
		.title {
		}
		.accordion__item {
		}
		.question {
		}
		.answer {
		}
		
	}
	

	.accordion{

		.question{
			padding-left: 3.4rem;
			position: relative;
			@include type-rubik(1.6rem, 600, 1.375, 0.02rem);
			color: var(--grey);
			display: block;
		}
		i{
			width: 2.4rem;
			height: 2.4rem;
			position: absolute;
			left: 0;
			top: -0.1rem;
			&::before, &::after {
				content: '';
				display: block;
				position: absolute;
				background: var(--blue-btn);
				-webkit-border-radius: 0.1rem;
						border-radius: 0.1rem;
				-webkit-transition: all 0.3s ease-out 0s;
				-o-transition: all 0.3s ease-out 0s;
				transition: all 0.3s ease-out 0s; }
			  &::before {
				width: 0.2rem;
				height: 1.8rem;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%) rotate(0deg);
				opacity: 1; }
			  &::after {
				width: 1.8rem;
				height: 0.2rem;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%); }
		}
		.answer{
			padding-left: 3.4rem;
			padding-top: 2rem;

			p{
				margin-bottom: 0;
				@include type-inter(1.6rem, 400, 1.75, 0);

			}
		}
		.accordion__item.item-opened .question i::before {
			transform: translate(-50%, -50%) rotate(360deg);
			opacity: 0; 
		}
		
	}

	/*------------ comparison end------------------- */

/* -------------------------------------------  Comparison page end ------------------------------------------------------------- */
