$rubik-font: 'Rubik', sans-serif;

$inter-font: 'Inter', sans-serif;

$brand-font: '', sans-serif;
$font-family-base: $brand-font;


// columns and between spasing
$grid-columns: 12;
$grid-gutter-width: 30px;


// min dimensions for breakpoints
$grid-breakpoints: (
  xxs: 0,
  xs: 600px,
  sm: 768px,
  md: 993px,
  lg: 1025px,
  xl: lg
);

// container max width
$container-max-widths: (
  sm: 100%,
  md: 1170px + $grid-gutter-width,
  lg: md,
  xl: lg
);


$brand-white: rgba(255, 255, 255, 1);
$brand-red: tomato;
